import React, { useEffect, useState } from "react";
import iconEllipsis from "../../../assets/images/icons/ellipsis.svg";
import iconMarkAsUnread from "../../../assets/images/icons/unread.svg";
import iconMarkAsRead from "../../../assets/images/icons/read.svg";
import {
  MessageActionModal,
  MessageActionItemList,
  MessageActionItemItem
} from "../../../styles/common";
import { useRef } from "react";
import { checkTokenValidity } from "../../../utils/utils";
import { useAuth } from '../../../context/AuthProvider/useAuth';
import { fireAlert, formatDate } from "../../../utils/utils";
import { MessageActionsButton, MessageActionsContainer } from "../../contacts/style";

export default function ItemOut({
  item,
  index,
  lineId,
  setLoading,
  details,
  setDetails
}) {
    const [viewAllModal, setViewAllModal] = useState(false);
    const [messageActions, setMessageActions] = useState(0);
    const messageRef = useRef();
    const auth = useAuth();

    const fetchReadUnreadMessage = async (pContact, pMessage, status) => {
        try {
            setLoading(true);
            await auth.putChatStatus({
                lineid: lineId,
                contactid: pContact,
                chatid: pMessage,
                status
            });

            const newDetails = details.filter((item) => {
                if (item.id === pMessage) {
                    item.readstatus = status;
                }

                return item;
            });

            setDetails([...newDetails]);

            //status === 0 ? setTempUnreadMarked([...tempUnreadMarked, pMessage]) : setTempUnreadMarked(tempUnreadMarked.filter(d => d !== pMessage));
        } catch ({ response }) {
            //console.log(JSON.stringify(response));
            checkTokenValidity(response.data);
            fireAlert(response.data.message);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <div className="d-flex flex-row justify-content-start" style={{ overflow: "hidden" }} key={index}>
                <div className="d-flex justify-content-between">
                    <p style={{ fontSize: 10 }} className="small mb-2 rounded-3 text-muted">{formatDate(item.createdat)}</p>
                    {//tempUnreadMarked.includes(item.id)
                        item.readstatus === 0 && <div className="d-flex ms-1 ms-auto"><img src={iconMarkAsUnread} width="20" height="20" alt="Icon Tic" /></div>}
                    <MessageActionsContainer>
                        <MessageActionsButton
                            active={viewAllModal}
                            onClick={() => {
                                setViewAllModal(!viewAllModal)
                                setMessageActions(item.id)
                            }}
                        >
                            <img src={iconEllipsis} width="15" height="15" alt="Ellipses" style={{ cursor: "pointer" }} className="mx-1" />
                        </MessageActionsButton>
                        <MessageActionModal ref={messageRef} active={viewAllModal && messageActions === item.id ? "true" : undefined}>
                            <MessageActionItemList>
                                {
                                    //!tempUnreadMarked.includes(item.id)
                                    item.readstatus === 1 ?
                                        <MessageActionItemItem
                                            active={undefined}
                                            onClick={() => {
                                                setViewAllModal(!viewAllModal);
                                                fetchReadUnreadMessage(item.contactid, item.id, 0)
                                            }}
                                        >
                                            <div className="d-flex w-auto">
                                                <img className="mt-1" src={iconMarkAsUnread} height="20" alt="Icon Tic" />
                                                <span style={{ fontSize: 10, color: "#640180" }} >Mark as Unread</span>
                                            </div>
                                        </MessageActionItemItem>
                                        :
                                        <MessageActionItemItem
                                            active={undefined}
                                            onClick={() => {
                                                setViewAllModal(!viewAllModal);
                                                fetchReadUnreadMessage(item.contactid, item.id, 1)
                                            }}
                                        >
                                            <div className="d-flex">
                                                <img className="mt-1" src={iconMarkAsRead} width="20" height="20" alt="Icon Tic" />
                                                <span style={{ fontSize: 10, color: "#640180" }}>Mark as Read</span>
                                            </div>
                                        </MessageActionItemItem>
                                }
                            </MessageActionItemList>
                        </MessageActionModal>
                    </MessageActionsContainer>
                </div>
            </div>
            {item.message.trim() &&
                <div className="d-flex flex-row justify-content-start" style={{ overflow: "hidden" }} key={index + "text"}>
                    <div className={"w-auto"}>
                        <p style={{ backgroundColor: "#640180" /* #2c7cb5 */ }} className="small py-2 px-3 ms-0 mb-1 text-white rounded-3">{item.message}</p>
                    </div>
                </div>
            }
            {item.media && item?.mediatype?.indexOf("image") >= 0 &&
                <div className="d-flex flex-row justify-content-start" style={{ overflow: "hidden" }} key={index + "image"}>
                    <div style={{ maxWidth: "25%" }}>
                        <p style={{ backgroundColor: "#640180" /* #2c7cb5 */ }} className="small py-3 px-3 ms-0 mb-1 rounded-3">
                            <a href={item?.media} target="_blank" rel="noopener noreferrer">
                                <img className="img-fluid rounded-3" src={item?.media} alt={item?.mediatype} />
                            </a>
                        </p>
                    </div>
                </div>
            }
            {item.media && item?.mediatype?.indexOf("audio") >= 0 &&
                <div className="d-flex flex-row justify-content-start" style={{ overflow: "hidden" }} key={index + "image"}>
                    <div style={{ maxWidth: "25%" }}>
                        <p style={{ backgroundColor: "#640180" /* #2c7cb5 */ }} className="small py-3 px-3 ms-0 mb-1 rounded-3">
                            <audio controls><source src={item?.media} /></audio>
                        </p>
                    </div>
                </div>
            }
            {item.media && item?.mediatype?.indexOf("video") >= 0 &&
                <div className="d-flex flex-row justify-content-start" style={{ overflow: "hidden" }} key={index + "text"}>
                    <div className={"w-auto"}>
                        <p style={{ backgroundColor: "#640180" /* #2c7cb5 */ }} className="small py-2 px-3 ms-0 mb-1 text-white rounded-3">(video not supported)</p>
                    </div>
                </div>
            }
        </>
    )
}