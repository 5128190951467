import React from "react";
import ReactDOM from "react-dom";
import { GlobalStyle } from "./styles/global";
import App from "./App";
//import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { RecoilRoot } from 'recoil';

import "./assets/global.css";

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <App />
    </RecoilRoot>
    <GlobalStyle />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.register();
//serviceWorkerRegistration.unregister();
/*serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    if (registration && Object.keys(registration.waiting).length > 0) {
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
      if (
        window.confirm(
          "New version! Ready to update?"
        )
      ) {
        registration.waiting.postMessage({ type: "SKIP_WAITING" });
        //alert("Por favor, atualize a página para carregar a nova versão!")
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
    }
  },
});*/
