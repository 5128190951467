import React, { useEffect, useState } from "react";
import iconArrow2 from "../../assets/images/icons/arrow-down.svg";
import ListItem from "./listItem";
import iconArrow from "../../assets/images/icons/arrow-right.svg";
import iconTic from "../../assets/images/icons/tic.svg";
import Loading from "../../components/fragments/Loading";
import AddEdit from "./addEdit";
import { FormattedMessage } from "react-intl";
import {
  ArrowOrder,
  CardFormContainer,
  CardFormHeader,
  CardFormHeaderActions,
  CardFormHeaderSearchMobile,
  DriverItemItem,
  DriverItemList,
  DriverItemModal,
  HideOnMobile,
  RosterHeaderButtons,
  RosterRefreshHeader,
  SelectedActions,
} from "../../styles/common";
import iconRefresh from "../../assets/images/icons/icon-refresh.svg";
import FeedBackModal from "../../components/fragments/feedBackModal";
import LoadOutButton from "../../components/fragments/loadOutButton";
import {
  ActiveBall,
  UsersTable,
  ViewAllButton,
  ViewAllContainerMobile,
} from "./style";
import LoadOutInput from "../../components/fragments/loadOutInput";
import LoadOutCheckbox from "../../components/fragments/loadOutCheckbox";
import TablePagination from "../../components/fragments/tablePagination";
import { DevicesHeaderButtonsMobile } from "./style";
import { useRef } from "react";
import { checkTokenValidity, useOnClickOutside } from "../../utils/utils";
//import { useHistory } from "react-router-dom";
import { useAuth } from "../../context/AuthProvider/useAuth";
import HeaderMobile from "./header-mobile";

export default function List({ fetchedData }) {
  //const history = useHistory();
  const auth = useAuth();
  const viewRef = useRef();

  //modal edit/add props
  useOnClickOutside(viewRef, () => setViewAllModal(false));
  const [viewAllModal, setViewAllModal] = useState(false);
  const [addEditRegister, setAddEditRegister] = useState(false);
  const [objectEditRegister, setObjectEditRegister] = useState({});
  const [idEditRegister, setIdEditRegister] = useState(undefined);
  const [modalObject, setModalObject] = useState({
    type: "alert",
    buttonLabel: "Delete",
    open: false,
    title: "",
    desc: "",
  });

  //ordering
  const [lastField, setLastField] = useState("name");
  const [lastOrder, setLastOrder] = useState(false);

  //Lists
  const [users, setUsers] = useState([]);
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState([]);
  const [checked, setChecked] = useState(1);
  const [search, setSearch] = useState("");

  //table cols data and ordering props
  const cols = [
    { title: 'name' },
    { title: 'phone' },
    { title: 'email' },
    { title: 'role' },
    { title: 'status' },
  ];

  //pagination props
  const [selected, setSelected] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const offset = selected * perPage - perPage;

  const isMobile = window.matchMedia(
    "(max-width: 1270px)" //"(pointer: coarse) and (hover: none)"
  ).matches;

  //table props
  const [hasUserChecked, setHasUserChecked] = useState(false);

  const setAllCheckboxes = (value) => {
    setHasUserChecked(value.target.checked);
    const newList = [ ...filter.map((x) => { return { ...x, active: value.target.checked } }) ];
    setFilter(newList);
  };

  const setCheckID = (id, value) => {
    const newList = [ ...filter.map((data) => {
      if (data.id === id) {
        data.active = value;
      }
      return data;
    }) ];
    setFilter(newList);
  }

  useEffect(() => {
    handleCheck();
  }, [filter]);

  function handleCheck() {
    var active = true;
    filter.forEach(item => {
      if (!item.active) {
        active = false;
        return;
      }
    });

    if (filter.length === 0) {
      active = false;
    }

    setHasUserChecked(active);
  }

  function deleteDataSelected() {
    setModalObject({
      type: "alert",
      buttonLabel: "Delete",
      open: true,
      title: "Confirm delete",
      desc: `Are you sure you want to delete this items?`,
      action: () => {
        setLoading(true);
    
        try {
          filter.forEach(async (item) => {
            if (item.active) {
              await deleteData(item.id);
            }
          });
        } catch ({ response }) {
          console.log(JSON.stringify(response));
          checkTokenValidity(response.data);
        } finally {
          setLoading(false);
          setModalObject({
            ...modalObject,
            open: false,
          });
        }
      }
    });
  }

  const fetchData = async () => {
    setLoading(true);

    try {
      //const params = { page: selected, rows: perPage, status, ...search && { query: search } };
      const { data } = await auth.getAllUsersByLineId();
      const newList = data.ret.map((item) => { item.active = false; return item; });
      setUsers(newList);
      //const newCount = data.ret.count.ACTIVE + data.ret.count.INACTIVE;
      //setCount(newCount);
      //setPageCount(Math.ceil(newCount / perPage));
    } catch (error) {
      checkTokenValidity(error.data);
      console.log(error.data);
    } finally {
      setLoading(false);
    }
  }

  const deleteData = async (userId) => {
    setLoading(true);

    try {
      const { data } = await auth.deleteUserById(userId);
      if (data.res) {
        //await fetchData();
        const newList = [ ...users.filter((item) => item.id !== userId) ];
        setUsers(newList);
        setSelected(1);
        //setUpdate(!update);
      }
    } catch ({ response }) {
      console.log(JSON.stringify(response));
      checkTokenValidity(response.data);
    } finally {
      setLoading(false);
    }
  }

  const fetchDataById = async (id) => {
    try {
      const { data } = await auth.getUserDetailsById(id);
      if (data.res) {
        setObjectEditRegister({ ...data.ret[0] });
      }
    } catch ({ response }) {
      checkTokenValidity(response.data);
      console.log(response.message);
    }
  }

  useEffect(() => {
    document.title = "LoadOut - Users";
    fetchData();
  }, [auth.lineData]);

  useEffect(() => {
    datatable();
  }, [checked, search, selected, lastField, lastOrder, users, perPage]);

  const getStatusFilter = (filtered) => {
    switch (checked) {
      case 0:
        return filtered?.filter((x) => x.status !== 1);
      case 1:
        return filtered?.filter((x) => x.status === 1);
      default:
        return filtered;
    }
  };
  
  function datatable() {
    const list = [ ...users ];

    //1 - Status
    const listStatus = getStatusFilter(list);

    //2 - Search
    var listSearch = listStatus;
    if (search.length) {
      listSearch = listStatus.filter((x) =>
          String(x.name).toLowerCase().includes(search.toLowerCase()) ||
          String(x.phone).toLowerCase().includes(search.toLowerCase()) ||
          String(x.company).toLowerCase().includes(search.toLowerCase())
        );
    }

    //3 - Sort
    listSearch.sort((a, b) => {
      if (String(a[lastField]).toLowerCase() < String(b[lastField]).toLowerCase()) {
        return lastOrder ? 1 : -1;
      } else if (String(a[lastField]).toLowerCase() > String(b[lastField]).toLowerCase()) {
        return lastOrder ? -1 : 1;
      } else {
        return 0;
      }
    });

    //4 - Paging
    const ListSlice = listSearch.slice(selected === 1 ? 0 : offset, selected * perPage);

    const newCount = listSearch.length;
    //console.log("count:", newCount);
    setCount(newCount);
    setFilter(ListSlice);
    setPageCount(Math.ceil(newCount / perPage));
  }

  function reorder(field) {
    if (field === lastField) {
      setLastOrder(!lastOrder);
    } else {
      setLastOrder(false);
      setLastField(field);
    }
  }

  function onChangeChecked(e) {
    setSearch("");
    setSelected(1);
    setChecked(e);
    setLastField("name");
    setLastOrder(false);
  }

  function onChangeSearch(e) {
    setSearch(e.target.value);
    setSelected(1);
    setLastField("name");
    setLastOrder(false);
  }

  useEffect(() => {
    setSelected(1);
  }, [perPage]);

  return (
    <>
      <FeedBackModal modalObj={modalObject} setModalObj={setModalObject} />
      <AddEdit
        openModal={addEditRegister}
        setOpenModal={setAddEditRegister}
        setId={setIdEditRegister}
        object={objectEditRegister}
        id={idEditRegister}
        refreshData={fetchData}
      />
      <CardFormContainer>
        <Loading visible={loading} />

        <CardFormHeader>
          <CardFormHeaderActions>
            <RosterRefreshHeader>
              <FormattedMessage id="users" />
              <img onClick={fetchData} src={iconRefresh} alt="Refresh Icon" />
            </RosterRefreshHeader>
            {users?.find((x) => x.active) ? (
              <HideOnMobile>
                <SelectedActions>
                  <LoadOutButton
                    onClick={deleteDataSelected}
                    icon="trash"
                    color="border"
                  />
                </SelectedActions>
              </HideOnMobile>
            ) : (
              <RosterHeaderButtons>
                <LoadOutButton
                  label="New User"
                  icon="add"
                  onClick={() => {
                    setObjectEditRegister({})
                    setIdEditRegister('')
                    setAddEditRegister(true)
                  }}
                />
              </RosterHeaderButtons>
            )}
          </CardFormHeaderActions>
          <CardFormHeaderSearchMobile>
            <LoadOutInput
              value={search}
              type="find"
              setSearch={setSearch}
              onChange={(e) => onChangeSearch(e) }
              highlight={<FormattedMessage id="form.search" />}
            />
            <ViewAllContainerMobile ref={viewRef}>
              <ViewAllButton
                active={viewAllModal}
                onClick={() => setViewAllModal(!viewAllModal)}
              >
                <span>
                  {checked === 1
                    ? "Active"
                    : checked === 0
                      ? "Inactive"
                      : "View All"}
                </span>
                <img src={iconArrow} alt="Arrow Icon" />
              </ViewAllButton>
              <DriverItemModal active={viewAllModal}>
                <DriverItemList>
                  <DriverItemItem
                    active={checked === 1}
                    onClick={() => {
                      onChangeChecked(1);
                      setViewAllModal(!viewAllModal);
                    }}
                  >
                    <ActiveBall status={1} />
                    <span>Active</span>
                    <img src={iconTic} alt="Icon Tic" />
                  </DriverItemItem>
                  <DriverItemItem
                    active={checked === 0}
                    onClick={() => {
                      onChangeChecked(0);
                      setViewAllModal(!viewAllModal);
                    }}
                  >
                    <ActiveBall status={0} />
                    <span>Inactive</span>
                    <img src={iconTic} alt="Icon Tic" />
                  </DriverItemItem>
                  <DriverItemItem
                    active={checked === -1}
                    onClick={() => {
                      onChangeChecked(-1);
                      setViewAllModal(!viewAllModal);
                    }}
                  >
                    <ActiveBall status={-1} />
                    <span>View All</span>
                    <img src={iconTic} alt="Icon Tic" />
                  </DriverItemItem>
                </DriverItemList>
              </DriverItemModal>
            </ViewAllContainerMobile>
          </CardFormHeaderSearchMobile>
          <DevicesHeaderButtonsMobile>
            {filter?.find((x) => x.active) ? (
                <LoadOutButton
                  label={<FormattedMessage id="delete" />}
                  onClick={deleteDataSelected}
                  icon="trash"
                  color="red"
                />
            ) : (
              <>
                <LoadOutButton
                  label="New User"
                  icon="add"
                  onClick={() => {
                    setObjectEditRegister({})
                    setIdEditRegister('')
                    setAddEditRegister(true)

                  }}
                />
              </>
            )}
          </DevicesHeaderButtonsMobile>
        </CardFormHeader>
        {isMobile && (
          <HeaderMobile checkAll={hasUserChecked} onChangeSelectedAll={setAllCheckboxes} lastField={lastField} lastOrder={lastOrder} reorder={reorder} />
        )}
        <UsersTable>
          <thead>
            <tr>
              {cols.map((field, index) => {
                return (
                  <th>
                    {index === 0 && <LoadOutCheckbox value={hasUserChecked} onChange={setAllCheckboxes} />}
                    <span key={index} onClick={() => { reorder(field.title); }}>
                      <FormattedMessage id={`tabela.${[field.title]}`} />
                      {lastField === field.title && (
                        <ArrowOrder
                          src={iconArrow2}
                          down={lastOrder}
                          alt="Arrow Icon"
                        />
                      )}
                    </span>
                  </th>
                )
              })}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filter?.map((d, i) => {
              return (
                <ListItem
                  key={i}
                  listData={d}
                  modalObject={modalObject}
                  setModalObject={setModalObject}
                  deleteData={deleteData}
                  updateData={fetchDataById}
                  setCheckID={setCheckID}
                  setAddEditRegister={setAddEditRegister}
                  setIdEditRegister={setIdEditRegister}
                  setObjectEditRegister={setObjectEditRegister}
                />
              );
            })}
          </tbody>
        </UsersTable>

        <TablePagination
          currentPage={selected}
          totalPages={pageCount || 1}
          nextAction={() => setSelected((prev) => prev + 1)}
          previousAction={() => setSelected((prev) => prev - 1)}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      </CardFormContainer>
    </>
  );
}
