import styled, { css } from "styled-components";
import appTheme from "../../../styles/themes";
import {
  displayXsSemiBold,
  textLgMedium,
  textLgSemiBold,
  textMdMedium,
  textMdSemiBold,
  textSmMedium,
  textSmRegular,
  textSmSemiBold,
  textXsMedium,
  textXsRegular,
  textXsSemiBold,
} from "../../../styles/typography";
import { DropdownField } from "../../../components/fragments/loadOutDropdown/style";

export const TooltipContainer = styled.div`
  ${({ }) => css`
    position: relative;
    display: inline-block;

    & .tooltiptext {
      ${textXsMedium}
      visibility: hidden;
      width: 120px;
      background-color: ${appTheme.colors.gray[700]};
      color: #fff;
      text-align: center;
      border-radius: 5px;
      padding: 5px 0;
      position: absolute;
      z-index: 10;
      top: -2px;
      left: 130%;
      
      /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
      opacity: 0;
      transition: opacity 0.4s;
      transition-delay: 1.5s;

      &:hover {
        visibility: hidden !important;
        opacity: 0 !important;
      }
    }

    & .tooltiptext::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 100%;
      margin-top: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent ${appTheme.colors.gray[700]} transparent transparent;
    }

    &:hover .tooltiptext {
      visibility: visible;
      opacity: 1;
    }
  `}
`;

export const LineBox = styled(DropdownField)`
  ${({ theme, active }) => css`
    background-color: ${appTheme.colors.white};
    border: 1px solid
      ${active ? appTheme.colors.purple[300] : appTheme.colors.gray[200]};
    /*padding: 10px 20px;*/
    width: auto;
    gap: 8px;
    text-align: center;
    @media (max-width: 750px) {
      padding: 9.14px 12.8px;
    }

    &:hover {
      border: 1px solid ${appTheme.colors.purple[300]};
    }

    & span {
      color: ${appTheme.colors.gray[500]};
      flex-shrink: 0;
      width: 100%;
    }
  `}
`;

export const GroupsTable = styled.table`
${({ theme }) => css`
width: 100%;

    & thead {
      background-color: ${appTheme.colors.gray[25]};

      @media (max-width: 1270px) {
        
        
      }

      & th {
        
        ${textXsSemiBold}
        color: ${appTheme.colors.gray[500]};
        padding: 14px;

        & > span {
          display: flex;
          gap: 4px;
        }

        &:first-of-type {
          min-width: 60px;
          display: flex;

          & span {
            margin-right: 1px;
            margin-left: 1px;
          }
        }

        &:nth-of-type(2) {
          min-width: 60px;
        }

        &:nth-of-type(3) {
          min-width: 60px;
        }

        &:nth-of-type(4) {
          min-width: 83px;
        }
        &:nth-of-type(5) {
          min-width: 73px;
        }
        &:nth-of-type(6) {
          min-width: 63px;
        }
      }
    }

    & tbody {
      
      & tr {
        ${textSmMedium}
        &:not(:first-of-type) {
          border-top: 1px solid ${appTheme.colors.gray[100]};
        }

        @media (max-width: 1270px) {
          
        }
      }
      & td {
        
        height: 52px;
        padding: 5px;

        &:first-of-type {
          padding: 5px;
        }
      }
    }
  `}
`;

export const QuantityBall = styled.span`
  ${({ theme, active }) => css`
    ${textSmMedium}
    margin-left: 8px;
    padding: 2px 10px;
    border-radius: 16px;
    transition: 0.3s ease;
    background-color: ${active
      ? appTheme.colors.purple[50]
      : appTheme.colors.gray[100]};
    color: ${active ? appTheme.colors.purple[500] : appTheme.colors.gray[700]};
  `}
`;