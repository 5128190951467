import { useEffect, useState } from "react";
import Loading from "../../components/fragments/Loading";
import { useRecoilState } from 'recoil';
import { LineState } from '../../state/states';
import FeedBackModal from "../../components/fragments/feedBackModal";
import Chat from "../chat";
import ChatList from "./chatList";
import NewChat from "./new";

export default function Chats() {
    const [lineData, setLineData] = useRecoilState(LineState);
    const [loading, setLoading] = useState(false);
    const [contactId, setContactId] = useState(0);
    const [lineId, setLineId] = useState(0);
    const [newChat, setNewChat] = useState(false);
    const [modalObject, setModalObject] = useState({
        type: "success",
        buttonLabel: "Ok",
        open: false,
        title: "Success",
        desc: "Description",
    });

    const [windowW, setWW] = useState(window.innerWidth);
    const [windowH, setWH] = useState(window.innerHeight);
    const [isMobile, setIsMobile] = useState(false);
    const handleSize = (e) => {
        setWW(e.target.innerWidth);
        setWH(e.target.innerHeight);
    }

    window.addEventListener('resize', handleSize);

    useEffect(() => {
        if (windowW <= 767) { setIsMobile(true) } else { setIsMobile(false) }
        return () => window.removeEventListener('resize', handleSize)
    }, [windowW]);

    useEffect(() => {
        if (!lineData) { return; }
        setLineId(lineData.id);
        setContactId(0);
        setNewChat(false);
    }, [lineData]);

    return (
        <>
            <FeedBackModal modalObj={modalObject} setModalObj={setModalObject} />
            <Loading visible={loading} />
            <div className="d-flex flex-row w-100 h-100" style={{paddingTop: "60px"}}>
                { ((isMobile && contactId === 0 && !newChat) || !isMobile) && 
                    <ChatList setLoading={setLoading} contactId={contactId} setContactId={setContactId} lineId={lineId} setNewChat={setNewChat} />
                }
                { contactId > 0 ? 
                    <Chat isEmbedded={true} setLoading={setLoading} setContactId={setContactId} contact_Id={contactId} line_Id={lineId} />
                :
                  newChat &&
                    <NewChat isEmbedded={true} setLoading={setLoading} setContactId={setContactId} contact_Id={contactId} line_Id={lineId} setNewChat={setNewChat} />
                }
            </div>
        </>
    );
}