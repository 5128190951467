import React, { useEffect, useState } from "react";
import { formatDate } from "../../utils/utils";

export default function ItemIn({
  item,
  index
}) {

    return (
        <>
            <div className="d-flex flex-row justify-content-end flex-end" key={index}>
                <div className={"float-end w-auto"}>
                    <p style={{ fontSize: 10 }} className="small mb-2 rounded-3 text-muted text-end">{item.uname + " • " + formatDate(item.createdat)}</p>
                </div>
            </div>
            {item.message &&
                <div className="d-flex flex-row justify-content-end flex-end" key={index + "text"}>
                    <div className={"float-end w-auto"}>
                        <p style={{ backgroundColor: "#e7e7e7" /* #FDF8FF */ }} className="small py-2 px-3 me-0 mb-1 rounded-3 text-end">{item.message}</p>
                    </div>
                </div>
            }
            {item.media && item?.mediatype?.indexOf("image") >= 0 &&
                <div className="d-flex flex-row justify-content-end" style={{ overflow: "hidden" }} key={index + "image"}>
                    <div style={{ maxWidth: "25%" }}>
                        <p style={{ backgroundColor: "#e7e7e7" /* #2c7cb5 */ }} className="small py-3 px-3 ms-0 mb-1 rounded-3">
                            <a href={item?.media} target="_blank" rel="noopener noreferrer">
                                <img className="img-fluid rounded-3" src={item?.media} alt={item?.mediatype} />
                            </a>
                        </p>
                    </div>
                </div>
            }
            {item.media && item?.mediatype?.indexOf("audio") >= 0 &&
                <div className="d-flex flex-row justify-content-end" style={{ overflow: "hidden" }} key={index + "image"}>
                    <div style={{ maxWidth: "25%" }}>
                        <p style={{ backgroundColor: "#e7e7e7" /* #2c7cb5 */ }} className="small py-3 px-3 ms-0 mb-1 rounded-3">
                            <audio controls><source src={item?.media} /></audio>
                        </p>
                    </div>
                </div>
            }
            {item.media && item?.mediatype?.indexOf("video") >= 0 &&
                <div className="d-flex flex-row justify-content-end" style={{ overflow: "hidden" }} key={index + "text"}>
                    <div className={"w-auto"}>
                        <p style={{ backgroundColor: "#e7e7e7" /* #2c7cb5 */ }} className="small py-2 px-3 ms-0 mb-1 text-white rounded-3">(video not supported)</p>
                    </div>
                </div>
            }
        </>
    )
}