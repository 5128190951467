import React from 'react';

export default function Loading( props ) {
    const visible = props.visible;
    if(!visible) return null;

    return (
        <div className='loading-container'>
            <div className="spinner-border">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    )
}