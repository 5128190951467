import styled, { css } from "styled-components";
import appTheme from "../../styles/themes";
import {
  displayXsSemiBold,
  textLgMedium,
  textLgSemiBold,
  textMdMedium,
  textMdSemiBold,
  textSmMedium,
  textSmRegular,
  textSmSemiBold,
  textXsMedium,
  textXsRegular,
  textXsSemiBold,
} from "../../styles/typography";
import { DropdownField } from "../../components/fragments/loadOutDropdown/style";

export const TooltipContainer = styled.div`
  ${({ }) => css`
    position: relative;
    display: inline-block;

    & .tooltiptext {
      ${textXsMedium}
      visibility: hidden;
      width: 120px;
      background-color: ${appTheme.colors.gray[700]};
      color: #fff;
      text-align: center;
      border-radius: 5px;
      padding: 5px 0;
      position: absolute;
      z-index: 10;
      top: -2px;
      left: 130%;
      
      /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
      opacity: 0;
      transition: opacity 0.4s;
      transition-delay: 1.5s;

      &:hover {
        visibility: hidden !important;
        opacity: 0 !important;
      }
    }

    & .tooltiptext::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 100%;
      margin-top: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent ${appTheme.colors.gray[700]} transparent transparent;
    }

    &:hover .tooltiptext {
      visibility: visible;
      opacity: 1;
    }
  `}
`;

export const LineBox = styled(DropdownField)`
  ${({ theme, active }) => css`
    background-color: ${appTheme.colors.white};
    border: 1px solid
      ${active ? appTheme.colors.purple[300] : appTheme.colors.gray[200]};
    /*padding: 10px 20px;*/
    width: auto;
    gap: 8px;
    text-align: center;
    @media (max-width: 750px) {
      padding: 9.14px 12.8px;
    }

    &:hover {
      border: 1px solid ${appTheme.colors.purple[300]};
    }

    & span {
      color: ${appTheme.colors.gray[500]};
      flex-shrink: 0;
      width: 100%;
    }
  `}
`;

