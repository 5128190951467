import * as PusherPushNotifications from '@pusher/push-notifications-web';

const usePusher = () => {
  const isClient = () =>
    !!(
      typeof window !== 'undefined' &&
      window.document &&
      window.document.createElement
    );

  if (isClient()) { //} && navigator.userAgent.indexOf('Chrome')> -1) {
    const parseJwt = (token) => {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );

      return JSON.parse(jsonPayload);
    };

    const lineList = JSON.parse(localStorage.getItem('lineList'));
    
    if (lineList.length > 0) {
      /*const decodeToken = parseJwt(getToken);
      const userId = decodeToken.id.toString();

      const tokenProvider = new PusherPushNotifications.TokenProvider({
        url: 'https://chat-api.dsploadout.com/v1/pusher/auth',
        headers: {
          'x-access-token': getToken,
          // Headers your auth endpoint needs
        },
      });*/

      const lines = [];
      lineList.map((item) => {
        lines.push(item.id.toString());
      });

      const beamsClient = new PusherPushNotifications.Client({
        instanceId: 'd62d18d7-982e-4a80-816f-1f54c66f9688',
      });

      //var beamsClient;

      /*window.navigator.serviceWorker.ready.then(serviceWorkerRegistration => {
        beamsClient = new PusherPushNotifications.Client({
          instanceId: 'd62d18d7-982e-4a80-816f-1f54c66f9688',
          serviceWorkerRegistration: serviceWorkerRegistration,
        })*/

        beamsClient.start()
          .then(() => beamsClient.setDeviceInterests(lines))
          .then(() => console.log('Interests has been set'))
          .catch((e) => console.error('Could not authenticate with Beams:', e));

      //})

      console.log('Beams Started');

      /*if(userId) {
        beamsClient
        .start()
        //.then(() => beamsClient.setUserId(userId, tokenProvider))
        //.then(() => console.log('User ID has been set'))
        .then(() => beamsClient.setDeviceInterests(lines))
        .then(() => console.log('Interests has been set'))
        .catch((e) => console.error('Could not authenticate with Beams:', e));
      }*/
    } else {
      console.log('There are no lines');
    }
  }
};

export default usePusher;
