import { useEffect, useRef, useState } from "react";
import mqtt from "precompiled-mqtt";
import { useAuth } from "../../context/AuthProvider/useAuth";
import { checkTokenValidity, fireAlert, formatDate } from "../../utils/utils";
import iconRefresh from "../../assets/images/icons/icon-refresh.svg";
import iconEdit from "../../assets/images/icons/icon-edit3.svg";
import { usePageVisibility } from "./usePageVisibility";
import useSound from "use-sound";
import sound from "../../assets/sounds/moon_drop.mp3";
import { FormattedMessage } from "react-intl";
import LoadOutInput from "../../components/fragments/loadOutInput";
import ContactItemList from "./ContactItemList";
import InfiniteScroll from "react-infinite-scroll-component";
import { useRecoilState } from 'recoil';
import { TenantState, MqttState } from '../../state/states';

export default function Chats({ setLoading, contactId, setContactId, lineId, setNewChat }) {
    const auth = useAuth();
    const [tenantData, setTenantData] = useRecoilState(TenantState);
    //const [lineData, setLineData] = useRecoilState(LineState);
    const [mqttData, setMqttData] = useRecoilState(MqttState);
    const isVisible = usePageVisibility();
    //const [loading, setLoading] = useState(false);
    const [pageList, setPageList] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [list, setList] = useState([]);
    const [offset, setOffset] = useState({ conversations: 30 });
    //const [contactId, setContactId] = useState(0);
    const [isGroup, setIsGroup] = useState(0);
    const [refresh, setRefresh] = useState(false);
    const [contactList, setContactList] = useState([]);
    const [topic, setTopic] = useState();
    const [client, setClient] = useState();
    const [chat, setChat] = useState();
    const [status, setStatus] = useState();
    const [sent, setSent] = useState();

    const [windowW, setWW] = useState(window.innerWidth);
    const [windowH, setWH] = useState(window.innerHeight);
    const [isMobile, setIsMobile] = useState(false);
    const handleSize = (e) => {
        setWW(e.target.innerWidth);
        setWH(e.target.innerHeight);
    }

    window.addEventListener('resize', handleSize);

    useEffect(() => {
        if (windowW <= 767) { setIsMobile(true) } else { setIsMobile(false) }
        return () => window.removeEventListener('resize', handleSize)
    }, [windowW]);

    const [hasMoreScroll, setHasMoreScroll] = useState({
        conversations: true
    });

    /*const listRef = useRef(list);
    const setList = data => {
        listRef.current = data;
        _setList(data);
    };*/

    const [play] = useSound(sound, {
        interrupt: true
    });

    useEffect(() => {
        document.title = "LoadOut Chat - Chat";
    }, []);

    useEffect(() => {
        //Getting the last chatid from list
        if (isVisible) {
            if (list.length === 0) {
                return;
            }
            statusCheckChatByIdAndLineId();
        } else {
            console.log("sumiu!");
        }

    }, [isVisible]);

    const statusCheckChatByIdAndLineId = async () => {
        //const lastChatId = listRef?.current[0].id;
        const lastChatId = list[0]?.id;
        try {
            const { data } = await auth.getChatCheckByChatAndLineId(lastChatId, lineId)
            if (data.res) {
                if (data.ret[0].count > 0) {
                    play();
                    fetchDataChatRefreshList();
                    /*setModalObject({
                        type: "error",
                        buttonLabel: "Ok",
                        open: true,
                        title: "Oops...",
                        desc: "There are new conversations, please refresh your list."
                        //action: () => {
                        //    setModalObject({ ...modalObject, open: false });
                        //}
                    });*/
                }
            } else {
                fireAlert(data.message);
            }
        } catch ({ response }) {
            checkTokenValidity(response.data);
            fireAlert(response.data.message);
        }
    }

    useEffect(() => {
        if (!pageList || !lineId) { return; }
        fetchDataChatList();
    }, [pageList, refresh, lineId]);

    const fetchDataChatList = async () => {
        console.log(pageList);
        try {
            setLoading(true);
            const { data } = await auth.getChatList({
                page: pageList,
                rows: offset.conversations,
                lineid: lineId
            });
            if (data.ret) {
                setHasMoreScroll({ conversations: data.ret.length > 0 });
                const newlist = [...list, ...data.ret];
                setList(pageList > 1 ? newlist : data.ret);
                console.log("data:", data.ret.length);
                console.log("total:", newlist.length);
            }
        } catch ({ response }) {
            checkTokenValidity(response.data);
            fireAlert(response?.data.message);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        console.log("MQTT:", mqttData);
        console.log("TENANT:", tenantData);
        console.log("LINE:", lineId);
        if (tenantData && lineId && mqttData) {
            const options = {
                port: mqttData.port,
                clientId: "cli_" + Math.random().toString(16).substring(2, 10),
                username: mqttData.user,
                password: mqttData.pass,
                clean: true
            };
            console.log(`${tenantData.tenant}/${lineId}/#`);
            setTopic(`${tenantData.tenant}/${lineId}/#`);
            setClient(mqtt.connect(mqttData.host, options));
            //fetchDataChatRefreshList();
        }
    }, [tenantData, lineId, mqttData]);

    //Status topic from mqtt
    useEffect(() => {
        if (status) {
            console.log(status);

            var newList = list.filter(item => {
                if (item.contactid === status.contactid) {
                    item.readstatus = status.readstatus;
                    //console.log("passou aqui");
                }
                return item;
            });
            setList([...newList]);
        }
    }, [status]);

    useEffect(() => {
        staticGetChatById();
    }, [chat]);

    const staticGetChatById = async () => {
        if (chat) {
            try {
                const { data } = await auth.getOneChatById(chat.chatid);
                if (data.res) {
                    //TODO: ver quando o id do group é o mesmo que o id do contact
                    var newList = list.filter(item => item.contactid !== data.ret[0].contactid);
                    newList = [data.ret[0], ...newList];
                    setList(newList);
                    //console.log(JSON.stringify(data));
                    //console.log(data.ret[0].contactid, contactId, data.ret[0].contactid === contactId);
                    
                    //If incoming, notify play
                    if (chat.notify) {
                        play();
                    }
                } else {
                    fireAlert(data.message);
                }
            } catch ({ response }) {
                checkTokenValidity(response?.data);
                console.log(response);
                fireAlert(response?.data.message);
            }
        }
    }

    //Sent topic from mqtt
    useEffect(() => {
        if (sent) {
            console.log(sent);

            var newList = list.filter(item => {
                if (item.id === sent.chatid) {
                    item.readstatus = sent.readstatus;
                }
                return item;
            });
            setList([...newList]);
        }
    }, [sent]);

    useEffect(() => {
        if (client && !client.connected) {
            client.on("connect", function () {
                console.log("conectou list!");
                client.subscribe(topic, function (err) {
                    console.log("inscreveu-se list!");
                    if (err) {
                        console.log("erro na inscrição list!");
                    }
                });

            });

            client.on("disconnect", function () {
                console.log("desconectou list!");
            });

            client.on("message", function (topic, message) {
                // message is Buffer
                //tenant/lineid/2/contactid/?/status = 0 or 1
                console.log(`${topic.toString()}: ${message.toString()}`);
                if (String(topic).indexOf("/chat") > 0) {
                    console.log(`Chat: ${message}`);
                    setChat(JSON.parse(message));
                };
                if (String(topic).indexOf("/status") > 0) {
                    console.log(`Status: ${message}`);
                    setStatus(JSON.parse(message));
                };
                if (String(topic).indexOf("/sent") > 0) {
                    console.log(`Sent: ${message}`);
                    setSent(JSON.parse(message));
                };
            });

            //VER quando o id do group é o mesmo id do contact

            /*client.publish("presence", "Hello mqtt", {
                retain: false,
                qos: 1
            });*/

            return function cleanup() {
                client.end();
                console.log("Desconectou List!");
            }
        }
    }, [client]);

    useEffect(() => {
        if (searchText.trim() === "" && searchText.length === 0) return

        /*const fetchBouncer = setTimeout(() => {
            fetchDataChatLikeSearch()
        }, !!searchText ? 2000 : 0);*/

        fetchDataChatLikeSearch();

        //return () => clearTimeout(fetchBouncer);
    }, [searchText]);

    const fetchDataChatLikeSearch = async () => {
        if (searchText.length === 0) {
            setContactList([]);
            return;
        }
        try {
            setLoading(true);
            const { data } = await auth.getChatLike({
                like: searchText.trim(),
            });
            if (data.res && searchText.length > 0) {
                const newList = [...data.group, ...data.contact];
                setContactList(newList);
                return;
            }
            setModalObject({
                type: "error",
                buttonLabel: "Ok",
                open: true,
                title: "Oops...",
                desc: data.message
                /*action: () => {
                    setModalObject({ ...modalObject, open: false });
                }*/
            });
        } catch ({ response }) {
            checkTokenValidity(response.data);
            fireAlert(response.data.message);

        } finally {
            setLoading(false);
        }
    }

    const fetchDataChatRefreshList = async () => {
        setPageList(1);
        setRefresh(!refresh);
    }

    function handleNew() {
        setContactId(0);
        setNewChat((s) => !s);
    }

    function fetchDataChatDetails(contactid, name, phone, isgroup) {
        setContactId(contactid);
        setSearchText("");
    }

    function openChat(event, contactid, name, phone, isgroup) {
        event.preventDefault();
        window.open(`chat/line/${lineId}/contact/${contactid}`, '_blank', 'noopener,noreferrer');
    }

    return (
        <>
            <div className="d-flex flex-column mx-3">
                <div className="d-flex flex-row align-items-center justify-content-between m-2">
                    <div>
                        <p className="fw-bold h3">Chats</p>
                    </div>
                    <div className="d-flex">
                        <div>
                            <img onClick={fetchDataChatRefreshList} src={iconRefresh} alt="Refresh Icon" title="Refresh List" className="btn btn-icon mt-1 p-0" />
                        </div>
                        <div>
                            <img onClick={handleNew} src={iconEdit} alt="New Message Icon" title="New Message" className="btn btn-icon p-0" />
                        </div>
                    </div>
                </div>
                <div className="input-group mb-0 p-2" style={{ marginTop: -15 }}>
                    <LoadOutInput
                        value={searchText}
                        setSearch={setSearchText}
                        type="find"
                        onChange={(e) => setSearchText(e.target.value.trim())}
                        highlight={<FormattedMessage id="search_contact" />}
                    />
                </div>
                { searchText.trim() ?
                    <div id="scroll-contact" data-mdb-perfect-scrollbar="true" className="ps-2 pe-2" style={{ height: isMobile ? windowH * 0.75 : "100%", width: isMobile ? "100%" : windowW * 0.33, overflow: "scroll" }}>
                        <ul className="list-unstyled">
                            {contactList?.map((contact, index) => {
                                return (
                                    <li className="mb-2">
                                        <ContactItemList key={index} details={contact} onClick={contact?.new ? fetchNewChat : fetchDataChatDetails} />
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                :
                    <div id="scroll-conversation" data-mdb-perfect-scrollbar="true" className="ps-2 pe-2" style={{ height: isMobile ? windowH * 0.75 : "100%", width: isMobile ? "100%" : windowW * 0.33, overflow: "scroll" }}>
                        <ul className="list-unstyled">
                            <InfiniteScroll
                                scrollableTarget="scroll-conversation"
                                dataLength={list.length}
                                next={() => {
                                    setPageList(pageList + 1);
                                }}
                                hasMore={hasMoreScroll.conversations}
                                endMessage={<p style={{ textAlign: "center" }}>End of Conversations</p>}
                            >
                                {list?.map((contact, index) => {
                                    return (<li
                                        style={{
                                            
                                            border: `${contact?.readstatus === 0 ? "3px solid #640180" : "transparent"}`
                                        }}
                                        className={`p-3 mb-2 rounded-3${contact.contactid === contactId && contact.isgroup === isGroup ? " bg-secondary bg-gradient text-white" : " bg-light bg-gradient"}`}
                                        
                                        key={index}>
                                        <div style={{cursor: "pointer"}} onClick={() => fetchDataChatDetails(contact.contactid, contact.name, contact.phone, contact.isgroup)} className="d-flex flex-row button justify-content-between align-items-top">
                                            <div>
                                                <small className="fw-bold" style={{ fontSize: 13 }}>{(contact?.name ?? "N/A")}</small>
                                            </div>
                                            <div>
                                                <small className="text-end" style={{ fontSize: 11 }}>{formatDate(contact?.createdat)}</small>
                                            </div>
                                        </div>
                                        <div className="button small" onClick={() => fetchDataChatDetails(contact.contactid, contact.name, contact.phone, contact.isgroup)} style={{ cursor: "pointer", fontSize: 13, display: contact.message?.trim() ? "block" : "none" }}>{contact?.message?.length > 100 ? contact?.message?.substring(0, 100) + "..." : contact?.message}</div>
                                        <div className="button small" onClick={() => fetchDataChatDetails(contact.contactid, contact.name, contact.phone, contact.isgroup)} style={{ cursor: "pointer", fontSize: 13, display: contact.message?.trim() ? "none" : "block" }}>(image)</div>
                                        <div className="d-flex justify-content-between" style={{ fontSize: 11 }}>
                                            <div style={{cursor: "pointer"}} onClick={() => fetchDataChatDetails(contact.contactid, contact.name, contact.phone, contact.isgroup)}>Last replied by {contact?.uname ? contact?.uname : contact?.name ? contact?.name : "N/A"}</div>
                                            <div className="d-flex flex-row gap-2">
                                                <svg style={{cursor: "pointer"}} className="button" xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 448 512" fill={contact.contactid === contactId ? "white" : "#640180"} onClick={(event) => openChat(event, contact.contactid, contact.name, contact.phone, contact.isgroup)}>
                                                    <path d="M384 32c35.3 0 64 28.7 64 64l0 320c0 35.3-28.7 64-64 64L64 480c-35.3 0-64-28.7-64-64L0 96C0 60.7 28.7 32 64 32l320 0zM160 160c-6.5 0-12.3 3.9-14.8 9.9s-1.1 12.9 3.5 17.4l40 40-71 71C114 302 112 306.9 112 312s2 10 5.7 13.7l36.7 36.7c3.6 3.6 8.5 5.7 13.7 5.7s10-2 13.7-5.7l71-71 40 40c4.6 4.6 11.5 5.9 17.4 3.5s9.9-8.3 9.9-14.8l0-144c0-8.8-7.2-16-16-16l-144 0z"/>
                                                </svg>
                                                {/*<svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512" fill={contact.contactid === contactId ? "white" : "#640180"} className={contact?.readstatus === 0 ? "visible" : "invisible"}>
                                                    <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/>
                                                </svg>*/}
                                                { contact?.readstatus ?
                                                <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512" fill={contact.contactid === contactId ? "white" : "#640180"}>
                                                    <path d="M255.4 48.2c.2-.1 .4-.2 .6-.2s.4 .1 .6 .2L460.6 194c2.1 1.5 3.4 3.9 3.4 6.5l0 13.6L291.5 355.7c-20.7 17-50.4 17-71.1 0L48 214.1l0-13.6c0-2.6 1.2-5 3.4-6.5L255.4 48.2zM48 276.2L190 392.8c38.4 31.5 93.7 31.5 132 0L464 276.2 464 456c0 4.4-3.6 8-8 8L56 464c-4.4 0-8-3.6-8-8l0-179.8zM256 0c-10.2 0-20.2 3.2-28.5 9.1L23.5 154.9C8.7 165.4 0 182.4 0 200.5L0 456c0 30.9 25.1 56 56 56l400 0c30.9 0 56-25.1 56-56l0-255.5c0-18.1-8.7-35.1-23.4-45.6L284.5 9.1C276.2 3.2 266.2 0 256 0z"/>
                                                </svg>
                                                :
                                                <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512" fill={contact.contactid === contactId ? "white" : "#640180"}>
                                                    <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48L48 64zM0 176L0 384c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-208L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/>
                                                </svg>
                                                /*<svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 448 512" fill={contact.contactid === contactId ? "white" : "#640180"}>
                                                    <path d="M64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zM218 271.7L64.2 172.4C66 156.4 79.5 144 96 144l256 0c16.5 0 30 12.4 31.8 28.4L230 271.7c-1.8 1.2-3.9 1.8-6 1.8s-4.2-.6-6-1.8zm29.4 26.9L384 210.4 384 336c0 17.7-14.3 32-32 32L96 368c-17.7 0-32-14.3-32-32l0-125.6 136.6 88.2c7 4.5 15.1 6.9 23.4 6.9s16.4-2.4 23.4-6.9z"/>
                                                </svg>*/
                                                }
                                            </div>
                                        </div>
                                    </li>)
                                })}
                            </InfiniteScroll>
                        </ul>
                    </div>
                }
            </div>
        </>
    );
}