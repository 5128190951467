import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../context/AuthProvider/useAuth";
import Loading from "../../../components/fragments/Loading";
import FeedBackModal from "../../../components/fragments/feedBackModal";
import InfiniteScroll from "react-infinite-scroll-component";
import mqtt from "precompiled-mqtt";
import { checkTokenValidity, useOnClickOutside, fireAlert, formatDate, useOnPasteImage, useOnFocusOut } from "../../../utils/utils";
import { LineBox, TooltipContainer } from "./style";
import logoCube from "../../../assets/images/icons/load-out-cube.svg";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import { FormattedMessage } from "react-intl";
import appTheme from "../../../styles/themes";
import Image from "../../../components/fragments/image/";
import Dynamic from "../../../components/fragments/dynamic/";
import Template from "../../../components/fragments/template/";
import LoadOutButton from "../../../components/fragments/loadOutButton";
import ItemIn from "./itemIn";
import ItemOut from "./itemOut";
import { useRecoilState } from 'recoil';
import { TenantState, LineListState, MqttState } from '../../../state/states';
import useSound from "use-sound";
import sound from "../../../assets/sounds/moon_drop.mp3";
import Group from "./group";

export default function Chat({ isEmbedded, setContactId, contact_Id, line_Id, setNewChat }) {
    const [loading, setLoading] = useState(false);
    const [modalObject, setModalObject] = useState({
        type: "success",
        buttonLabel: "Ok",
        open: false,
        title: "Success",
        desc: "Description",
    });
    const [hasMoreScroll, setHasMoreScroll] = useState({
        messages: true
    });
    const [offset, setOffset] = useState({ messages: 20 });
    const [pageDetails, setPageDetails] = useState(1);
    const [details, setDetails] = useState([]);
    const [contact, setContact] = useState();
    const [text, setText] = useState("");
    const [fileName, setFileName] = useState(undefined);
    const [file, setFile] = useState(undefined);
    const [showEmoji, setShowEmoji] = useState(false);
    const [showImage, setShowImage] = useState(false);
    const [showDynamic, setShowDynamic] = useState(false);
    const [showTemplate, setShowTemplate] = useState(false);
    const [textPosition, setTextPosition] = useState(0);
    const [isGroup, setIsGroup] = useState(0);
    const [userInfo, setUserInfo] = useState();
    const [client, setClient] = useState();
    const [chat, setChat] = useState();
    const [status, setStatus] = useState();
    const [sent, setSent] = useState();
    const [topic, setTopic] = useState();
    const [tenantData, setTenantData] = useRecoilState(TenantState);
    //const [lineData, setLineData] = useRecoilState(LineListState);
    const [mqttData, setMqttData] = useRecoilState(MqttState);
    const [list, _setList] = useState([]);
    //const [pageList, setPageList] = useState(1);
    const [line, setLine] = useState();
    const [showGroup, setShowGroup] = useState(false);
    const [group, setGroup] = useState([]);

    const pasteRef = useRef();
    useOnPasteImage(pasteRef, (e) => handlePaste(e));
    const emojiRef = useRef();
    useOnClickOutside(emojiRef, () => setShowEmoji(false));
    const imageRef = useRef();
    useOnClickOutside(imageRef, () => setShowImage(!fileName ? false : true));
    const dynamicRef = useRef();
    useOnClickOutside(dynamicRef, () => setShowDynamic(false));
    const templateRef = useRef();
    useOnClickOutside(templateRef, () => setShowTemplate(false));

    const listRef = useRef(list);
    const setList = data => {
        listRef.current = data;
        _setList(data);
    };

    const auth = useAuth();

    const [play] = useSound(sound, {
        interrupt: true
    });

    const [windowW, setWW] = useState(window.innerWidth);
    const [windowH, setWH] = useState(window.innerHeight);
    const [isMobile, setIsMobile] = useState(false);
    const handleSize = (e) => {
        setWW(e.target.innerWidth);
        setWH(e.target.innerHeight);
    }

    window.addEventListener('resize', handleSize);

    useEffect(() => {
        if (windowW <= 767) { setIsMobile(true) } else { setIsMobile(false) }
        return () => window.removeEventListener('resize', handleSize)
    }, [windowW]);

    useEffect(() => {
        if (!line_Id) { return };

        loadLineData(line_Id);
         
    }, [line_Id]);

    async function loadLineData(lineid) {
        setLoading(true);
        
        try {
            const { data } = await auth.getLineById(lineid);
            if (data.res) {
                setLine(data.ret[0]);
            }
        } catch ({ response }) {
            setModalObject({ type: "error", title: "Error",  desc: response.data.message, open: true, ...modalObject });
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        document.title = "LoadOut Chat - Chat";
        var userinfo = {};
        try {
            userinfo = auth.userContext;
            setUserInfo(userinfo);
        } catch (error) {
            console.log("👾 invalid token format", error);
            return true;
        }

        /*if (userinfo?.chgpss === 1) {
            return null;
        }*/

        //document.addEventListener("visibilitychange", onFocus);

        //return function cleanup() {
            //document.removeEventListener("visibilitychange", onFocus);
        //}

    }, []);

    useEffect(() => {
        console.log("MQTT:", mqttData);
        console.log("TENANT:", tenantData);
        console.log("LINE:", line_Id);
        if (tenantData && line_Id && mqttData) {
            const options = {
                port: mqttData.port,
                clientId: "cli_" + Math.random().toString(16).substring(2, 10),
                username: mqttData.user,
                password: mqttData.pass,
                clean: true
            };
            console.log(`${tenantData.tenant}/${line_Id}/#`);
            setTopic(`${tenantData.tenant}/${line_Id}/#`);
            setClient(mqtt.connect(mqttData.host, options));
        }
    }, [tenantData, line_Id, mqttData]);

    //Status topic from mqtt
    useEffect(() => {
        if (status) {
            console.log(status);

            var newList = list.filter(item => {
                if (item.contactid === status.contactid) {
                    item.readstatus = status.readstatus;
                    //console.log("passou aqui");
                }
                return item;
            });
            setList([...newList]);
        }
    }, [status]);

    //Sent topic from mqtt
    useEffect(() => {
        if (sent) {
            console.log(sent);

            /*if (_contactId !== sent.contactid) {
                return;
            }*/

            var newDetails = details.filter(item => {
                if (item.id === sent.chatid) {
                    item.readstatus = sent.readstatus;
                }
                return item;
            });
            setDetails([...newDetails]);
        }
    }, [sent]);

    useEffect(() => {
        if (client && !client.connected) {
            client.on("connect", function () {
                console.log("conectou!");
                client.subscribe(topic, function (err) {
                    console.log("inscreveu-se!");
                    if (err) {
                        console.log("erro na inscrição!");
                    }
                });

            });

            client.on("disconnect", function () {
                console.log("desconectou!");
            });

            client.on("message", function (topic, message) {
                // message is Buffer
                //tenant/lineid/2/contactid/?/status = 0 or 1
                console.log(`${topic.toString()}: ${message.toString()}`);
                if (String(topic).indexOf("/chat") > 0) {
                    console.log(`Chat: ${message}`);
                    setChat(JSON.parse(message));
                };
                if (String(topic).indexOf("/status") > 0) {
                    console.log(`Status: ${message}`);
                    setStatus(JSON.parse(message));
                };
                if (String(topic).indexOf("/sent") > 0) {
                    console.log(`Sent: ${message}`);
                    setSent(JSON.parse(message));
                };
            });

            //VER quando o id do group é o mesmo id do contact

            /*client.publish("presence", "Hello mqtt", {
                retain: false,
                qos: 1
            });*/

            return function cleanup() {
                client.end();
                console.log("Desconectou!");
            }
        }
    }, [client]);

    useEffect(() => {
        staticGetChatById();
    }, [chat]);

    const staticGetChatById = async () => {
        if (chat) {
            try {
                const { data } = await auth.getOneChatById(chat.chatid);
                if (data.res) {
                    //TODO: ver quando o id do group é o mesmo que o id do contact
                    var newList = list.filter(item => item.contactid !== data.ret[0].contactid);
                    newList = [data.ret[0], ...newList];
                    setList(newList);
                    //console.log(JSON.stringify(data));
                    //console.log(data.ret[0].contactid, contactId, data.ret[0].contactid === contactId);
                    if (data.ret[0].contactid == "_contactId") {
                        const listDetails = details.filter(item => item.id !== data.ret[0].id);
                        const newDetails = [data.ret[0], ...listDetails];
                        setDetails(newDetails);
                        //console.log(JSON.stringify(newDetails));
                        //If outgoing, not set status
                        if (chat.notify) {
                            fetchDataChatStatus(data.ret[0].lineid, data.ret[0].contactid, data.ret[0].id);
                        }
                    }
                    //If incoming, notify play
                    if (chat.notify) {
                        play();
                    }
                } else {
                    fireAlert(data.message);
                }
            } catch ({ response }) {
                checkTokenValidity(response.data);
                console.log(response);
                fireAlert(response.data.message);
            }
        }
    }

    const fetchDataChatStatus = async (pLine, pContact, pChat) => {
        try {
            setLoading(true);
            await auth.getChatStatus({
                lineid: pLine,
                contactid: pContact,
                chatid: pChat
            });
        } catch ({ response }) {
            checkTokenValidity(response.data);
            fireAlert(response.data.message);
        } finally {
            setLoading(false);
        }
    }

    function maskNumber(number) {
        if (!number) { return ""; }
        var name = "";
        switch (true) {
            case number.indexOf("+1") === 0:
                name = "(" + number.substring(2, 5) + ") " + number.substring(5, 8) + "-" + number.substring(8);
                break;
            case number.indexOf("+55") === 0:
                name = "(" + number.substring(3, 5) + ") " + number.substring(5, 10) + "-" + number.substring(10);
                break;
            default:
                name = number;
        }

        return name;
    }

    function handleDrop(e) {
        e.preventDefault();
        const clipboardItems = e.dataTransfer.files;
        const items = [].slice.call(clipboardItems).filter(function (item) {
            // Filter the image items only
            return /^image\//.test(item.type);
        });
        if (items.length === 0) {
            return;
        }

        const item = items[0];
        let file = new File([item], item.kind, { type: item.type, lastModified: new Date().getTime() }, 'utf-8');
        onFileChange(file);
    }

    function handlePaste(e) {
        if (e.clipboardData.getData('text')) {
            return;
        }

        e.preventDefault();
        const clipboardItems = e.clipboardData.items;
        const items = [].slice.call(clipboardItems).filter(function (item) {
            // Filter the image items only
            return /^image\//.test(item.type);
        });
        if (items.length === 0) {
            return;
        }

        const item = items[0];
        const blob = item.getAsFile();
        let file = new File([blob], item.kind, { type: item.type, lastModified: new Date().getTime() }, 'utf-8');
        onFileChange(file);
    }

    function onFileChange(file) {
        console.log(file);
        if (file?.target?.files?.length === 0) {
            return;
        }
        if (!file) {
            setFileName(undefined);
            setFile(undefined);
            return;
        }
        setFileName((window.URL || window.webkitURL).createObjectURL(file));
        setFile(file);
        setShowImage(true);
    }

    async function handleSend() {

        /*if (contactId === 0 && (newCode + newPhone).length < 12) {
            setModalObject({
                type: "error",
                buttonLabel: "Ok",
                open: true,
                title: "Oops...",
                desc: "Please inform a valid phone number",
                /*action: () => {
                    setModalObject({ ...modalObject, open: false });
                }/*
            });
            return;
        }*/

        setLoading(true);

        const newGroupList = group.map(item => {
            if (isNaN(item.id)) item.id = 0;
            return item;
        });

        const formData = new FormData();
        //if (file) {
        formData.append("media", file);
        //}
        formData.append("lineid", line.id);
        formData.append("contacts", JSON.stringify(newGroupList));
        formData.append("message", text);
        formData.append("isgroup", isGroup);
        /*if (contactId === 0) {
            const phoneConcat = newCode + newPhone;
            formData.append("phone", phoneConcat);
        }*/

        const sendRet = await auth.postChatMultiple(formData)
            .then(chatsend => {
                var ret = {};
                if (chatsend.data?.res) {
                    ret = {
                        res: true,
                        errno: 0,
                        message: chatsend.data.ret
                    };
                } else {
                    ret = {
                        res: false,
                        errno: -1,
                        message: chatsend.data.ret
                    };
                }

                return  ret;
            })
            .catch(err => {
                const ret = {
                    res: false,
                    errno: -1,
                    message: err.response.data.message || err.request || err.message
                };

                return  ret;
            }).finally(() => {
                setLoading(false);
            });

        if (!sendRet.res) {

            setModalObject({
                type: "error",
                buttonLabel: "Ok",
                open: true,
                title: "Oops...",
                desc: sendRet.message //err.response.data.message || err.request || err.message
                /*action: () => {
                    setModalObject({ ...modalObject, open: false });
                }*/
            });

            return;
        }

        //const ret = data.ret;
        /*if (contactId !== data.contactid) {
            return;
        }*/
       
        const ts = new Intl.DateTimeFormat("sv-SE", { year: "numeric", month: "numeric", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit" });

        const newData = {
            ...sendRet.message,
            id: sendRet.message.insertId,
            userid: userInfo.id,
            uname: userInfo.fname.concat(userInfo.lname ? " " + userInfo.lname : ""),
            message: text,
            readstatus: 1,
            createdat: ts.format(new Date())
        };

        const newDetails = [newData, ...details];

        setDetails(newDetails);
        setShowEmoji(false);
        setShowImage(false);
        setShowTemplate(false);
        setShowDynamic(false);
        setFileName(undefined);
        setFile(undefined);
        setText("");
        //console.log(data);

        /*if (contactId === 0) {
            //setContactId(ret.contactid);
            fetchDataChatDetails(ret.contactid, ret.name, ret.phone, 0);
            //console.log("->", ret.contactid);
        }*/
    }

    function handleChange(e) {
        setText(e.target.value);
    }

    function handleShowEmoji(e) {
        e.stopPropagation();
        setShowEmoji(!showEmoji);
        setShowImage(!fileName ? false : true);
        setShowTemplate(false);
        setShowDynamic(false);
    }

    function handleEmoji(e) {
        const position = textPosition; //document.getElementById("textarea").selectionStart;
        let textBeforeCursorPosition = text.substring(0, position);
        let textAfterCursorPosition = text.substring(position, text.length);
        setText(textBeforeCursorPosition + e.native + textAfterCursorPosition);
        setShowEmoji(false);
        document.getElementById("textarea")?.focus();
    }

    function handleShowImage() {
        setShowImage(!fileName ? !showImage : true);
        setShowEmoji(false);
        setShowTemplate(false);
        setShowDynamic(false);
    }

    function handleShowDynamic(e) {
        setShowDynamic(!showDynamic);
        setShowImage(!fileName ? false : true);
        setShowEmoji(false);
        setShowTemplate(false);
    }

    function handleShowTemplate(e) {
        setShowTemplate(!showTemplate);
        setShowImage(!fileName ? false : true);
        setShowEmoji(false);
        setShowDynamic(false);
    }

    function handleTemplate(message) {
        setText(message);
        document.getElementById("textarea")?.focus();
    }

    function handleText(tag) {
        const position = textPosition; //document.getElementById("textarea").selectionStart;
        let textBeforeCursorPosition = text.substring(0, position);
        let textAfterCursorPosition = text.substring(position, text.length);
        setText(textBeforeCursorPosition + tag + textAfterCursorPosition);
        document.getElementById("textarea")?.focus();
    }

    return (
        <>
            <FeedBackModal modalObj={modalObject} setModalObj={setModalObject} />
            <Loading visible={loading} />
            <Group 
                openModal={showGroup}
                setOpenModal={setShowGroup}
                group={group}
                setGroup={setGroup}
            />

            <div className="d-flex flex-column w-100 h-100">
                { !isEmbedded &&
                    <div className="d-flex flex-row border-bottom justify-content-between">
                        <img src={logoCube} alt="Ícone" className="p-3" />
                        <LineBox className="align-self-center">{`${line?.name || ""} • ${maskNumber(line?.number)}`}</LineBox>
                        <div />
                    </div>
                }

                <div className="d-flex flex-row border-bottom justify-content-between mx-4 py-2">
                    <div className="d-flex flex-row">
                        { isEmbedded &&
                            <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 24 24" style={{ cursor: "pointer"}}
                            onClick={() => {
                                setContactId(0);
                                setNewChat(false);
                            }}>
                                <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
                                <path d="M0 0h24v24H0z" fill="none" />
                            </svg>
                        }
                        <div className="fw-bold align-self-center">{group.length} <FormattedMessage id="contacts" /></div>
                    </div>
                    <LoadOutButton
                        className='fw-bolder'
                        style={{ cursor: 'pointer' }}
                        onClick={() => { 
                            setShowGroup(true);
                        }}
                        size="160"
                        icon="add"
                        label={<FormattedMessage id="add_contacts" />}
                    />
                </div>

                <div id="scroll-messages" className="d-flex flex-column-reverse h-100 justify-content-between overflow-auto mx-4 py-2" data-mdb-perfect-scrollbar="true">
                    <InfiniteScroll
                        scrollableTarget="scroll-messages"
                        dataLength={details.length}
                        next={() => {
                            setPageDetails(pageDetails + 1);
                        }}
                        style={{ display: "flex", flexDirection: "column-reverse" }}
                        inverse={true}
                        hasMore={hasMoreScroll.messages}
                        endMessage={<p style={{ textAlign: "center" }}>End of Messages</p>}
                    >
                        {
                            details?.map((detailsItem, index) => {
                                return detailsItem.userid === 0 ?
                                    <ItemOut item={detailsItem} index={index} setLoading={setLoading} lineId={line_Id} details={details} setDetails={setDetails} />
                                :
                                    <ItemIn item={detailsItem} index={index} />
                                })
                        }
                    </InfiniteScroll>
                </div>

                <div className="d-flex flex-row mt-auto px-3 py-2 input-group">
                    <form className="chat-form rounded-3 w-100" style={{ backgroundColor: "#F6F9FB" }} data-emoji-form="" onSubmit={(e) => {
                        e.preventDefault();
                        //postDataChatCreate()
                    }}>
                        <span ref={emojiRef} style={{ visibility: showEmoji ? 'visible' : 'hidden', position: "absolute", width: "350px", top: "-436px", zIndex: "101" }}>
                            <Picker data={data} theme="light" OnClickOutside={() => showEmoji && setShowEmoji(false)} previewPosition="none" maxFrequentRows="2" onEmojiSelect={emoji => handleEmoji(emoji)} />
                        </span>
                        <span ref={imageRef} style={{ display: showImage ? 'block' : 'none', position: "absolute", top: "-350px", zIndex: "100" }}>
                            <Image openModal={showImage} setOpenModal={setShowImage} fileName={fileName} onFileChange={onFileChange} />
                        </span>
                        <span ref={templateRef} className="m-0" style={{ display: showTemplate ? 'block' : 'none', position: "absolute", top: "-350px", zIndex: "100" }}>
                            <Template openModal={showTemplate} setOpenModal={setShowTemplate} setLoading={setLoading} setText={handleTemplate} />
                        </span>
                        <span ref={dynamicRef} className="m-0" style={{ display: showDynamic ? 'block' : 'none', position: "absolute", top: "-350px", zIndex: "100" }}>
                            <Dynamic openModal={showDynamic} setOpenModal={setShowDynamic} setLoading={setLoading} setText={handleText} />
                        </span>
                        <textarea ref={pasteRef} onDrop={handleDrop} onPaste={handlePaste} id="textarea" className="form-control border-0  shadow-none p-2 px-3"
                            onBlur={(e) => setTextPosition(e.target.selectionStart)}
                            value={text}
                            onKeyDown={(e) => {
                                if (e.key === "Enter" && !e.shiftKey && (text.trim().length > 0 || fileName)) {
                                    e.preventDefault();
                                    handleSend();
                                }
                                //console.log(e.key);
                                /*if (e.key === "Enter" && qtRows < 5) setQtRows(qtRows + 1)
                                if (text.split("\n").length < qtRows) {
                                    setQtRows(qtRows - 1)
                                }*/
                            }}
                            onChange={(e) => handleChange(e)}
                            placeholder="Type a message or paste/drop an image"
                            rows={2}
                            data-emoji-input=""
                            data-autosize="true"
                            style={{ width: "100%", backgroundColor: "#F6F9FB", overflow: "hidden", overflowWrap: "break-word", resize: "none", minHeight: "45px", maxHeight: "250px", overflowY: "auto", borderRadius: "15px" }}>
                        </textarea>
                    </form>
                    <div className="d-flex justify-content-between w-100">
                        <div className="d-flex flex-row">
                            <TooltipContainer className="btn-icon btn-link btntext-body text-decoration-none align-self-center" style={{ cursor: "pointer" }}>
                                <div className="tooltiptext"><FormattedMessage id="emojis" /></div>
                                <span style={{ width: "24px", height: "24px", fill: "#6e747c" }} onClick={handleShowEmoji}>
                                    <svg viewBox="0 0 24 24" width="24" height="24">
                                        <circle cx="12" cy="12.5" r="10" fill="transparent"></circle>
                                        <path fill={showEmoji ? appTheme.colors.purple[500] : ""} d="M12 23.14a10.75 10.75 0 1 1 10.75-10.75A10.77 10.77 0 0 1 12 23.14zm0-20a9.25 9.25 0 1 0 9.25 9.25A9.26 9.26 0 0 0 12 3.14zm5.36 12.34a.74.74 0 0 0-.27-1 .76.76 0 0 0-1 .27 4.68 4.68 0 0 1-8.12 0 .75.75 0 0 0-1-.27.74.74 0 0 0-.27 1 6.18 6.18 0 0 0 10.72 0zm-2.73-5a.88.88 0 1 0 .87-.87.88.88 0 0 0-.87.83zm-7 0a.88.88 0 1 0 1.75 0 .88.88 0 0 0-1.75 0zm3.17-.29"></path>
                                    </svg>
                                </span>
                            </TooltipContainer>
                            <TooltipContainer className="btn-icon btn-link btntext-body text-decoration-none align-self-center" style={{ cursor: "pointer" }}>
                                <div className="tooltiptext"><FormattedMessage id="images" /></div>
                                <span style={{ width: "24px", height: "24px", fill: "#6e747c" }} onClick={handleShowImage}>
                                    <svg viewBox="0 0 24 24" height="24" width="24">
                                        <path fill="transparent" d="M3.5 3.5h17v17h-17z"></path>
                                        <path fill={showImage ? appTheme.colors.purple[500] : ""} d="M8.5 10.75A2.261 2.261 0 0 1 6.25 8.5 2.261 2.261 0 0 1 8.5 6.25a2.261 2.261 0 0 1 2.25 2.25 2.261 2.261 0 0 1-2.25 2.25zm0-3a.754.754 0 0 0-.75.75c0 .411.339.75.75.75s.75-.339.75-.75a.763.763 0 0 0-.75-.75zM19 2.25H5A2.763 2.763 0 0 0 2.25 5v14A2.763 2.763 0 0 0 5 21.75h14A2.763 2.763 0 0 0 21.75 19V5A2.763 2.763 0 0 0 19 2.25zM3.75 19V5c0-.686.564-1.25 1.25-1.25h14c.686 0 1.25.564 1.25 1.25v8.19l-3.72-3.72a.753.753 0 0 0-1.06 0L4.74 20.2a1.234 1.234 0 0 1-.99-1.2zM19 20.25H6.81L16 11.06l4.25 4.25V19c0 .686-.564 1.25-1.25 1.25z"></path>
                                    </svg>
                                </span>
                            </TooltipContainer>
                            <TooltipContainer className="btn-icon btn-link btntext-body text-decoration-none align-self-center" style={{ cursor: "pointer" }}>
                                <div className="tooltiptext"><FormattedMessage id="templates" /></div>
                                <span style={{ width: "24px", height: "24px", fill: "#6e747c" }} onClick={handleShowTemplate}>
                                    <svg viewBox="0 0 24 24" width="24" height="24">
                                        <path fill="transparent" d="M8.5 2h7v4h-7z"></path>
                                        <path fill="transparent" d="M4.5 4.5h15v17h-15z"></path>
                                        <path fill={showTemplate ? appTheme.colors.purple[500] : ""} d="M15 1.25A1.76 1.76 0 0 1 16.75 3v.25H18A2.75 2.75 0 0 1 20.75 6v14A2.75 2.75 0 0 1 18 22.75H6A2.75 2.75 0 0 1 3.25 20V6A2.75 2.75 0 0 1 6 3.25h1.25V3A1.76 1.76 0 0 1 9 1.25h6zm3 20c.69 0 1.25-.56 1.25-1.25V6c0-.69-.56-1.25-1.25-1.25h-1.25V5A1.76 1.76 0 0 1 15 6.75H9A1.76 1.76 0 0 1 7.25 5v-.25H6c-.69 0-1.25.56-1.25 1.25v14c0 .69.56 1.25 1.25 1.25h12zM14.486 9.5a.764.764 0 1 1 0 1.527H12.88v5.749a.873.873 0 0 1-1.747 0v-5.749H9.514a.764.764 0 1 1 0-1.527h4.972zM15 5.25a.25.25 0 0 0 .25-.25V3a.25.25 0 0 0-.25-.25H9a.25.25 0 0 0-.25.25v2c0 .138.112.25.25.25h6z"></path>
                                    </svg>
                                </span>
                            </TooltipContainer>
                            <TooltipContainer className="btn-icon btn-link btntext-body text-decoration-none align-self-center" style={{ cursor: "pointer" }}>
                                <div className="tooltiptext"><FormattedMessage id="dynamic" /></div>
                                <span style={{ width: "24px", height: "24px", fill: "#6e747c" }} onClick={handleShowDynamic}>
                                    <svg viewBox="0 0 24 24" width="24" height="24">
                                        <rect fill="transparent" x="4" y="3" width="16" height="18.2"></rect>
                                        <path fill={showDynamic ? appTheme.colors.purple[500] : ""} d="M8.34,22H4a.8.8,0,0,1-.8-.8V2.94A.79.79,0,0,1,4,2.15H8.34a.79.79,0,0,1,.8.79.8.8,0,0,1-.8.8H4.76V20.4H8.34a.79.79,0,0,1,.8.79A.8.8,0,0,1,8.34,22Zm12.5-.8V2.94a.79.79,0,0,0-.8-.79H15.66a.79.79,0,0,0-.8.79.8.8,0,0,0,.8.8h3.58V20.4H15.66a.79.79,0,0,0-.8.79.8.8,0,0,0,.8.8H20A.8.8,0,0,0,20.84,21.19Z"></path>
                                    </svg>
                                </span>
                            </TooltipContainer>
                            <TooltipContainer className="btn-icon btn-link btntext-body text-decoration-none align-self-center" style={{ cursor: "pointer" }}>
                                <div className="tooltiptext"><FormattedMessage id="translator" /></div>
                                <span style={{ width: "24px", height: "24px", fill: "#6e747c" }}>
                                    <svg viewBox="0 0 24 24" height="24" width="24">
                                        <circle cx="12" cy="12" r="10" fill="transparent"></circle>
                                        <path d="M12 1.25A10.75 10.75 0 1 0 22.75 12 10.76 10.76 0 0 0 12 1.25zm9.21 10H16.7a16 16 0 0 0-3-8.34 9.27 9.27 0 0 1 7.51 8.34zM8.8 12.75h6.4a14.56 14.56 0 0 1-3.2 8.1 14.47 14.47 0 0 1-3.2-8.1zm0-1.5a14.56 14.56 0 0 1 3.2-8.1 14.47 14.47 0 0 1 3.2 8.1zm1.51-8.34a16.08 16.08 0 0 0-3 8.34H2.79a9.27 9.27 0 0 1 7.52-8.34zm-7.52 9.84H7.3a16 16 0 0 0 3 8.34 9.27 9.27 0 0 1-7.51-8.34zm10.9 8.34a16.08 16.08 0 0 0 3-8.34h4.51a9.27 9.27 0 0 1-7.51 8.34z"></path>
                                    </svg>
                                </span>
                            </TooltipContainer>
                        </div>
                        <div className="d-flex justify-content-end mt-2">
                            <LoadOutButton
                                disabled={(text.trim().length === 0 && !fileName) || !group.length}
                                onClick={handleSend}
                                icon="send"
                                label={"Send"}
                                size="xs"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}