import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import LoadOutInput from "../../../components/fragments/loadOutInput";
import ModalPattern from "../../../components/fragments/modalPattern";
import { RegisterFormOneColumn } from "../../../styles/common";
import FeedBackModal from "../../../components/fragments/feedBackModal";
import { useRef } from "react";
import { checkTokenValidity, useOnClickOutside } from "../../../utils/utils";
import { useAuth } from '../../../context/AuthProvider/useAuth'
import TablePagination from "../../../components/fragments/tablePagination";
import LoadOutButton from "../../../components/fragments/loadOutButton";
import Loading from "../../../components/fragments/Loading";
import {
  GroupsTable,
  QuantityBall
} from "./style";

export default function Group({
  openModal,
  setOpenModal,
  group,
  setGroup
}) {
  const auth = useAuth();
  const [groupObject, setGroupObject] = useState([]);
  const [modalObject, setModalObject] = useState({
    type: "success",
    buttonLabel: "Ok",
    open: false,
    title: "Success",
    desc: "Issue deleted",
  });

  //pagination props
  const [contacts, setContacts] = useState([]);
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState([]);
  const [selected, setSelected] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [pageCount, setPageCount] = useState(0);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [newContact, setNewContact] = useState(false);
  const offset = selected * perPage - perPage;

  //ordering props
  const [lastField, setLastField] = useState("name");
  const [lastOrder, setLastOrder] = useState(false);

  const resetForm = () => {
    setSearch("");
    setGroupObject([]);
    setOpenModal(false);
    setContacts([]);
    setSelected(1);
  };

  const fetchContactIdsData = async () => {
    setLoading(true);

    try {
      const { data } = await auth.getAllContactsByLineId();
      setContacts(data.ret);
      setPageCount(Math.ceil(data.ret.length / perPage));
      //setGroupObject(group);
    } catch (error) {
      //checkTokenValidity(response.data)
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function onSubmit() {
    setGroup(groupObject);
    setOpenModal(false);
  }

  useEffect(() => {
    if (openModal) {
      setGroupObject(group);
      fetchContactIdsData();
    } else {
      resetForm();
    }
  }, [openModal]);

  useEffect(() => {
    datatable();
  }, [search, selected, lastField, lastOrder, contacts, groupObject]);
  
  function datatable() {
    console.log("Group:", group);
    console.log("groupObject:", groupObject);
    const list = [ ...(search.length ? contacts : groupObject) ];

    //1 - Status
    //const listStatus = getStatusFilter(list);

    //2 - Search
    var listSearch = list;
    if (search.length) {
      listSearch = list.filter((x) =>
        String(x.name).toLowerCase().includes(search.toLowerCase()) ||
        String(x.phone).toLowerCase().includes(search.toLowerCase())
      );
    }

    //3 - Sort
    listSearch.sort((a, b) => {
      if (String(a[lastField]).toLowerCase() < String(b[lastField]).toLowerCase()) {
        return lastOrder ? 1 : -1;
      } else if (String(a[lastField]).toLowerCase() > String(b[lastField]).toLowerCase()) {
        return lastOrder ? -1 : 1;
      } else {
        return 0;
      }
    });

    //4 - Paging
    const ListSlice = listSearch.slice(selected === 1 ? 0 : offset, selected * perPage);

    const newCount = listSearch.length;
    //console.log("count:", newCount);
    setCount(newCount);
    setFilter(ListSlice);
    setPageCount(Math.ceil(newCount / perPage));
  }

  function reorder(field) {
    if (field === lastField) {
      setLastOrder(!lastOrder);
    } else {
      setLastOrder(false);
      setLastField(field);
    }
  }

  useEffect(() => {
    if (!isNaN(search) && filter.length === 0 && (search.length === 10 || (search.length === 12 && search.indexOf("+") === 0))) {
      setNewContact(true);
    } else {
      setNewContact(false);
    }

  }, [filter]);

  function maskNumber(number) {
    if (!number) { return ""; }
    var masked = "";
    
    if (number.length === 10) {
      masked = "+1 (" + number.substring(0, 3) + ") " + number.substring(3, 6) + "-" + number.substring(6);
    }

    if (number.length === 12 && number.indexOf("+") === 0) {
      masked = number.substring(0, 2) + " (" + number.substring(2, 5) + ") " + number.substring(5, 8) + "-" + number.substring(8);
    }

    /*switch (true) {
      case number.indexOf("+1") === 0:
        masked = "(" + number.substring(2, 5) + ") " + number.substring(5, 8) + "-" + number.substring(8);
        break;
      case number.indexOf("+55") === 0:
        masked = "(" + number.substring(3, 5) + ") " + number.substring(5, 10) + "-" + number.substring(10);
        break;
      default:
        masked = number;
    }*/

    return masked;
}

  useEffect(() => {
    if (!search.length) {
      setSelected(1);
      //setNewContact(false);
    }
  }, [search]);

  function onChangeSearch(e) {
    const searchValue = e.target.value.trim();
    setSearch(searchValue);
    setSelected(1);
    setLastField("name");
    setLastOrder(false);
  }

  function addNewContact() {
    var number = search;
    if (search.indexOf("+1") < 0) {
      number = `+1${search}`;
    }

    const newNumber = {
      id: Math.random().toString(16).substring(2, 10),
      name: "New Contact",
      phone: number
    };
    groupObject.push(newNumber);

    setSearch("");
  }

  return (
    <>
      <Loading visible={loading} />
      <FeedBackModal modalObj={modalObject} setModalObj={setModalObject} />
      <ModalPattern
        open={openModal}
        setOpen={setOpenModal}
        title={<FormattedMessage id="add_contacts" />}
        saveAction={onSubmit}
        cancelAction={resetForm}
      >
        <RegisterFormOneColumn width={'650px'}>
          <LoadOutInput
            value={search}
            type="find"
            setSearch={setSearch}
            onChange={(e) => onChangeSearch(e) }
            highlight={<FormattedMessage id="search_new" />}
          />
          <LoadOutButton
            disabled={!newContact}
            className='fw-bolder'
            style={{ cursor: 'pointer' }}
            onClick={() => { 
                addNewContact();
            }}
            size="xs"
            icon="add"
            label={`Add ${newContact ? maskNumber(search) : ""}`}
          />
          <>
            <GroupsTable>
              <thead>
                <tr>
                  {['name', 'phone'].map((field, index) => {
                    return (
                      <th key={index}>
                        <span>
                          <FormattedMessage id={`tabela.${field}`} />
                        </span>
                      </th>
                    )
                  })}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {filter?.map((d, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <span>{d.name}</span>
                      </td>
                      <td>
                        <span>{maskNumber(d.phone)}</span>
                      </td>
                      <td>
                        {groupObject.find(c => c.id === d.id) ?
                          <LoadOutButton
                            className='fw-bolder'
                            style={{ cursor: 'pointer' }}
                            onClick={() => { 
                              setGroupObject(groupObject.filter(c => c.id !== d.id));
                            }}
                            color='red'
                            size="xs"
                            icon="trash"
                          />
                          :
                          <LoadOutButton
                            className='fw-bolder'
                            style={{ cursor: 'pointer' }}
                            onClick={() => { 
                              setGroupObject([...groupObject, d]);
                              setSearch("");
                            }}
                            size="xs"
                            icon="add"
                          />
                        }
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </GroupsTable>
            <TablePagination
              hidePerPage={true}
              currentPage={selected}
              totalPages={pageCount || 1}
              nextAction={() => setSelected((prev) => prev + 1)}
              previousAction={() => setSelected((prev) => prev - 1)}
            />
          </>
        </RegisterFormOneColumn>
      </ModalPattern>
    </>
  );
}
