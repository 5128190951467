import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getLocalStoragedToken } from "../../api/axios";
import { useAuth } from "./useAuth";

export default function ChkAuth({ children }) {
  const auth = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (!getLocalStoragedToken() || !auth?.userContext?.id) {
      // auth.logout();
      // history.push("/");
      return;
    }

    // var dateExpiring = new Date(auth.userContext.exp * 1000)
    //   .toLocaleString()
    //   .substr(0, 10);
    // var dateNow = new Date().toLocaleString().substr(0, 10);

    // dateExpiring =
    //   dateExpiring.split("/")[2] +
    //   "-" +
    //   dateExpiring.split("/")[1] +
    //   "-" +
    //   dateExpiring.split("/")[0];
    // dateNow =
    //   dateNow.split("/")[2] +
    //   "-" +
    //   dateNow.split("/")[1] +
    //   "-" +
    //   dateNow.split("/")[0];

    // //console.log('login expiring: ', new Date(userInfo.exp * 1000).toLocaleString(), ', date now: ', new Date().toLocaleString())
    // //Verify if token expired
    // var dateUserInfo = new Date(auth.userContext.exp * 1000);
    // var dateActual = new Date(Date.now());

    // if (dateActual.getTime() < dateUserInfo.getTime()) {
    //   if (dateNow == dateExpiring) {
    //     api
    //       .post(
    //         "login/refresh",
    //         {},
    //         { headers: { "x-access-token": auth.tokenContext } }
    //       )
    //       .then((data) => {
    //         localStorage.setItem("token", data.data.token);
    //       })
    //       .catch((err) => {
    //         console.log("login expired, redirecting to login");
    //         localStorage.setItem("token", null);
    //         history.push("/");
    //       });
    //     return;
    //   }
    // } else {
    //   console.log("login expired, redirecting to login");
    //   localStorage.setItem("token", null);
    //   history.push("/");
    //   return;
    // }
  });

  useEffect(() => {
    const operatorAllowedRoutes = [
      /*"/dashboard",*/
      "/chat",
      "/chats",
      "/templates",
      "/contacts",
      "/groups",
      "/change-password",
      "/import",
      "/import/contact/confirm"
    ];

    if (
      auth?.userContext?.role === 3 &&
      !operatorAllowedRoutes.includes(history.location.pathname.toLowerCase())
    ) {
      history.push("/chats");
      return;
    }
  }, [history.location.pathname]);

  return children;
}
