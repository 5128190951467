import { useRef, useState } from "react";
import ticIcon from "../../../assets/images/icons/tic.svg";
import arrow from "../../../assets/images/icons/arrow-right.svg";
import { DropdownData, DropdownDataContainer, DropdownField } from "./style";
import { useOnClickOutside } from "../../../utils/utils";

const LoadOutDropdown = ({
  placeholder,
  fieldStyle,
  spanColor,
  value,
  setValue,
  data,
}) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setOpenDropdown(false));

  return (
    <DropdownField
      spanColor={spanColor}
      onClick={() => setOpenDropdown(!openDropdown)}
      style={fieldStyle}
      active={openDropdown ? "openDropdown" : undefined}
      ref={ref}
    >
      <span>{value ? value : placeholder}</span>
      <img src={arrow} alt="Seta" onClick={() => setOpenDropdown(!openDropdown)} />
      <DropdownDataContainer active={openDropdown}>
        {data.map((item, i) => {
          return (
            <DropdownData
              onClick={() => {
                setValue(item);
                setOpenDropdown(!openDropdown);
              }}
              active={item === value ? "true" : undefined}
              key={i}
            >
              {item} {item === value && <img src={ticIcon} alt="Tic" />}
            </DropdownData>
          );
        })}
      </DropdownDataContainer>
    </DropdownField>
  );
};

export default LoadOutDropdown;
