import styled, { css } from "styled-components/macro";
import appTheme from "../../../styles/themes";
import {
  textMdMedium,
  textMdRegular,
  textSmMedium,
} from "../../../styles/typography";

export const DropdownField = styled.div`
  ${({ theme, spanColor, active }) => css`
    display: flex;
    width: 100%;
    cursor: pointer;
    position: relative;
    border-radius: 8px;
    padding: 10px 16px;
    align-items: center;
    border: ${active
      ? `1px solid ${appTheme.colors.purple[300]}`
      : "1px solid transparent"};
    background-color: ${active
      ? appTheme.colors.white
      : appTheme.colors.gray[100]};
    justify-content: space-between;
    transition: 0.3s ease;

    & span {
      ${spanColor ? textMdRegular : textSmMedium}
      color: ${spanColor ? spanColor : appTheme.colors.purple[900]};
    }

    & > img {
      transition: 0.4s ease;
      position: absolute;
      top: 50%;
      right: 17px;
      transform: translate(0, -50%) ${active ? "rotate(-90deg)" : "rotate(90deg)"};
    }
  `}
`;

export const DropdownDataContainer = styled.ul`
  ${({ theme, active }) => css`
    left: 0;
    width: 100%;
    z-index: 50;
    max-height: 320px;
    position: absolute;
    overflow-y: scroll;
    overflow-x: hidden;
    opacity: ${active ? '1' : '0'};
    top: ${active ? "50px" : "50px"};
    pointer-events: ${active ? "all" : "none"};
    background-color: ${appTheme.colors.white};
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1),
      0px 4px 6px -2px rgba(16, 24, 40, 0.05);
    transition: 0.3s ease;
    border-radius: 8px;
  `}
`;

export const DropdownData = styled.li`
  ${({ theme, active }) => css`
    ${textMdMedium}
    display: flex;
    text-align: left;
    padding: 10px 14px;
    justify-content: space-between;
    color: ${appTheme.colors.gray[900]};
    background-color: ${active
      ? appTheme.colors.purple[25]
      : appTheme.colors.white};
    transition: 0.3s ease;

    &:hover {
      background-color: ${appTheme.colors.purple[25]};
    }
  `}
`;
