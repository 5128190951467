import React, { useEffect, useState } from "react";
import { ModalContainer } from "./style";
import FeedBackModal from "../feedBackModal";
import { FormattedMessage } from "react-intl";
import { useAuth } from '../../../context/AuthProvider/useAuth';
import LoadOutInput from "../../../components/fragments/loadOutInput";
import LoadOutButton from "../loadOutButton";
import AddTemplate from "./addTemplate";
//import InfiniteScroll from "react-infinite-scroll-component";

export default function Template({
  openModal,
  setOpenModal,
  setLoading,
  setText
}) {
  const auth = useAuth();
  const [templateList, setTemplateList] = useState([]);
  const [modalObject, setModalObject] = useState({
    type: "success",
    buttonLabel: "Ok",
    open: false,
    title: "Success",
    desc: "Issue deleted",
  });
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState([]);
  const [addTemplate, setAddTemplate] = useState(false);
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    if (!openModal && !refresh) {
      return;
    }

    setLoading(true);
    setSearch("");
    
    try {
      ( async () => {
        const { data } = await auth.getAllTemplatesByLineId();
        setTemplateList(data.ret);
        setFilter(data.ret);
      })();
    } catch ( error ) {
      setModalObject({
        type: "error",
        buttonLabel: "Ok",
        open: true,
        title: "Oops...",
        desc: error?.data.message,
      });
    } finally {
      setLoading(false);
      setRefresh(false);
    }
  }, [openModal, refresh]);

  function handleClick(message) {
    setText(message);
    setOpenModal(false);
  }

  useEffect(() => {
    if (!templateList) return;

    if (search.length === 0) {
      setFilter(templateList);
      return;
    } 

    const newList = templateList.filter((item) => 
      item.name.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
      item.message.toLowerCase().indexOf(search.toLowerCase()) > -1
    );
    setFilter(newList);
  }, [search]);

  return (
    <>
      <FeedBackModal modalObj={modalObject} setModalObj={setModalObject} />
      <AddTemplate
        openModal={addTemplate}
        setOpenModal={setAddTemplate}
        refreshData={setRefresh}
        setLoading={setLoading}
      />
      <ModalContainer
        style={{ height: "350px", width: "350px", position: "fixed", textAlign: "start", alignItems: "start" }}
        open={openModal}
        setOpen={setOpenModal}
      >
        <div className="small fw-bold text-center mb-2">Templates</div>
        <div className="d-flex flex-row gap-2">
          <LoadOutInput
            value={ search }
            type="find"
            onChange={(e) => setSearch(e.target.value) }
            setSearch={ setSearch }
            highlight={<FormattedMessage id="form.search" />}
          />
          <LoadOutButton 
            icon="add" 
            size="60"
            onClick={() => { setAddTemplate(true); }}
          />
        </div>
        <div id="scroll-conversation text-start" data-mdb-perfect-scrollbar="true" style={{ height: "calc(100% - 70px)", overflow: "scroll", marginLeft: 8, marginTop: 10  }}>
          <ul className="list-unstyled">
            { filter.map( item => {
              return (
                <>
                <li onClick={() => handleClick(item.message)} style={{ fontSize: 14 }} key={item.id}>
                  <span className="fw-bold">{item.name}</span>
                  <span style={{ width: "calc(100% - 8px"}} className="btn d-inline-block py-0 px-1 m-1 bg-purple text-white text-start text-wrap">{item.message.substring(0, 90).concat(item.message.length > 90 ? "..." : "")}</span>
                </li>
                </>
              );
            })}
          </ul>
        </div>
      </ModalContainer>
    </>
  );
}
